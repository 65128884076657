import {FunctionComponent, ReactElement} from "react";
import {Col, Container, Image, Row} from "react-bootstrap";
import {PhotoSwipeGallery} from "react-photoswipe";
import 'react-photoswipe/lib/photoswipe.css';
import {AppUrl} from "../../../../../types/AppUrl";


const ArtistLuiseWalter: FunctionComponent = (): ReactElement => {

  let items = [
    {
      src: AppUrl.getBaseUrl()+'/img/2022/luisewalter/widkatze.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/luisewalter/thumbnail/widkatze.jpg',
      w: 675, h: 900,
      title: '<div class="imageCaption fadeIn"><div>Wildkatze</div><div>Öl auf Leinwand</div><div>40 x 30cm</div><div>2021</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/luisewalter/amor.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/luisewalter/thumbnail/amor.jpg',
      w: 800, h: 1067,
      title: '<div class="imageCaption fadeIn"><div>Amor</div><div>Öl auf Papier</div><div>30 x 20cm</div><div>2021</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/luisewalter/der-Funke-LuiseWalter.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/luisewalter/thumbnail/der-Funke-LuiseWalter.jpg',
      w: 1030, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>der Funke</div><div>Öl auf Leinwand</div><div>150 x 130cm</div><div>2021</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/luisewalter/pilz.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/luisewalter/thumbnail/pilz.jpg',
      w: 850, h: 850,
      title: '<div class="imageCaption fadeIn"><div>Pilz I</div><div>Öl auf Leinwand</div><div>30 x 30cm</div><div>2020</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/luisewalter/Pilze.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/luisewalter/thumbnail/Pilze.jpg',
      w: 1200, h: 886,
      title: '<div class="imageCaption fadeIn"><div>Pilz II</div><div>Öl auf Papier</div><div>20 x 25cm</div><div>2020</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/luisewalter/sunset2020.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/luisewalter/thumbnail/sunset2020.jpg',
      w: 1200, h: 885,
      title: '<div class="imageCaption fadeIn"><div>Himmel II</div><div>Öl auf Leinwand</div><div>40 x 50cm</div><div>2020</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/luisewalter/babyface.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/luisewalter/thumbnail/babyface.jpg',
      w: 1200, h: 1501,
      title: '<div class="imageCaption fadeIn"><div>babyface</div><div>Öl auf Holz</div><div>40 x 30cm</div><div>2020</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/luisewalter/IMG_0327.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/luisewalter/thumbnail/IMG_0327.jpg',
      w: 1200, h: 1194,
      title: '<div class="imageCaption fadeIn"><div>Kreisel</div><div>Öl auf Leinwand</div><div>30 x 30cm</div><div>2020</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/luisewalter/DasNest.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/luisewalter/thumbnail/DasNest.jpg',
      w: 1200, h: 1207,
      title: '<div class="imageCaption fadeIn"><div>Das Nest</div><div>Öl auf Leinwand</div><div>30 x 30cm</div><div>2020</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/luisewalter/maedchen-in-weisser-jacke.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/luisewalter/thumbnail/maedchen-in-weisser-jacke.jpg',
      w: 1200, h: 1564,
      title: '<div class="imageCaption fadeIn"><div>Mädchen in weißer Jacke</div><div>Öl auf Leinwand</div><div>70 x 50cm</div><div>2019</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/luisewalter/quisquilie.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/luisewalter/thumbnail/quisquilie.jpg',
      w: 1200, h: 1387,
      title: '<div class="imageCaption fadeIn"><div>Quisquilie</div><div>Öl auf Leinwand</div><div>150 x 130cm</div><div>2019</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/luisewalter/IMG_0862.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/luisewalter/thumbnail/IMG_0862.jpg',
      w: 763, h: 1024,
      title: '<div class="imageCaption fadeIn"><div>Mädchen mit Löffel</div><div>Öl auf Leinwand</div><div>60 x 80cm</div><div>2019</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/luisewalter/Zappelphilipp.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/luisewalter/thumbnail/Zappelphilipp.jpg',
      w: 765, h: 1024,
      title: '<div class="imageCaption fadeIn"><div>Zappelphilipp</div><div>Öl auf Leinwand</div><div>60 x 80cm</div><div>2019</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/luisewalter/IMG_8835.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/luisewalter/thumbnail/IMG_8835.jpg',
      w: 1200, h: 1440,
      title: '<div class="imageCaption fadeIn"><div>Junge, nach rechts schauend</div><div>Öl auf Leinwand</div><div>40 x 50cm</div><div>2019</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/luisewalter/IMG_8836.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/luisewalter/thumbnail/IMG_8836.jpg',
      w: 1200, h: 1425,
      title: '<div class="imageCaption fadeIn"><div>Mädchen, nach links schauend</div><div>Öl auf Leinwand</div><div>40 x 50cm</div><div>2019</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/luisewalter/arkanum_Luise-Walter_2019.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/luisewalter/thumbnail/arkanum_Luise-Walter_2019.jpg',
      w: 1200, h: 1803,
      title: '<div class="imageCaption fadeIn"><div>Arcanum</div><div>Öl auf Leinwand</div><div>60 x 90cm</div><div>2019</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/luisewalter/IMG_0870.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/luisewalter/thumbnail/IMG_0870.jpg',
      w: 819, h: 1024,
      title: '<div class="imageCaption fadeIn"><div>Symbiose</div><div>Öl auf Leinwand</div><div>70 x 100cm</div><div>2018</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/luisewalter/luchalibre.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/luisewalter/thumbnail/luchalibre.jpg',
      w: 1040, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>lucha libre</div><div>Öl auf Leinwand</div><div>150 x 130cm</div><div>2021</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/luisewalter/IMG_0883.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/luisewalter/thumbnail/IMG_0883.jpg',
      w: 845, h: 1024,
      title: '<div class="imageCaption fadeIn"><div>blurred portrait I</div><div>Öl auf Leinwand</div><div>50 x 60cm</div><div>2019</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/luisewalter/IMG_0921.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/luisewalter/thumbnail/IMG_0921.jpg',
      w: 1200, h: 1443,
      title: '<div class="imageCaption fadeIn"><div>blurred portrait IV</div><div>Öl auf Leinwand</div><div>50 x 60cm</div><div>2019</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/luisewalter/IMG_0909.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/luisewalter/thumbnail/IMG_0909.jpg',
      w: 787, h: 1024,
      title: '<div class="imageCaption fadeIn"><div>blurred portrait III</div><div>Öl auf Leinwand</div><div>100 x 130cm</div><div>2019</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/luisewalter/wolke.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/luisewalter/thumbnail/wolke.jpg',
      w: 1200, h: 1108,
      title: '<div class="imageCaption fadeIn"><div>Wolke - blurred Landscape I</div><div>Öl auf Leinwand</div><div>30 x 30cm</div><div>2019</div></div>'
    },

  ];

  let options = {
    history: false,
    focus: false,
    showAnimationDuration: 600,
    hideAnimationDuration: 600,
    closeOnScroll: false,
    shareEl: false,
    fullscreenEl: false,
    addCaptionHTMLFn: function (item: any, captionEl: any, isFake: boolean) {
      // item      - slide object
      // captionEl - caption DOM element
      // isFake    - true when content is added to fake caption container
      //             (used to get size of next or previous caption)

      if (!item.title) {
        captionEl.children[0].innerHTML = '';
        return false;
      }
      captionEl.children[0].innerHTML = item.title;
      return true;
    }
  };

  const getThumbnailContent = (item: any) => {
    return (
      <img src={item.thumbnail} alt={item.title} style={{margin: '3px'}}/>
    );
  }

  return (
    <div className={'siteContainer'}>
      <Container>
        <Row>
          <Col md={12}>
            <div className={'eventPeriod'}>30.04.2022 – 07.07.2022, Galeriegespräch am 13.05.2022 um 19:00 Uhr</div>
            <div className={'eventPeriod'}>„Das entschwundene Land“ - Malerei und Skulptur</div>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Image src={AppUrl.getBaseUrl()+'/img/2022/luisewalter/luisewalter.jpg'}
                   className={'artistImage '}
                   alt={'Luise Walter'}/>
            <div className={'artistImageText'}>© Chris Poller</div>
          </Col>
          <Col md={8}>
            <div className={'artistName'}>Luise Walter</div>
            <div className={'artistBio'}>
              In Weimar geboren, hat Luise Walter von 2001 bis 2008 Medienkunst an der Hochschule für Grafik und Buchkunst in Leipzig studiert. Seit 2011 arbeitet sie als freischaffende Malerin und Fotografin.
              Die Arbeiten von Luise Walter Arbeiten zeichnen sich durch eine ausgeprägter eigene Handschrift aus, die auf die Zugehörigkeit zur (Neuen) Leipziger Schule basiert. Im Fokus ihrer Arbeiten stehen figurative Malerei und Porträts von Kindern mit versteckten humoristischen Botschaften. Seit Mai 2019 hat die freischaffende Künstlerin Luise Walter Hohenkuhnsdorf als ihren Lebens- und Arbeitsmittelpunkt gewählt. Dort hat sie sich ihre Atelierscheune mit Atelier- und Galerieraum und Werkstatt ausgebaut.
              Sie braucht die ländliche Idylle, die Natur und die Stille für ihre schöpferische Arbeit. Dies spiegelt sich auch in ihren Motiven wider.
              Ihre künstlerischen Fähigkeiten gibt sie als Dozentin mit Kursen in Finsterwalde, Herzberg und Jüterbog und auch in der JVA Heidering an Amateurkünstler weiter.
            </div>
            <div className={"additionalInfos"}>Im Internet:<br/>
              <a href={"http://www.luisewalter.de"} className={'externalLink'}>www.luisewalter.de</a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <PhotoSwipeGallery items={items} options={options} thumbnailContent={getThumbnailContent}/>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default ArtistLuiseWalter;
