import {ReactElement} from "react";
import {Col, Container, Image, Row} from "react-bootstrap";

const Location = (): ReactElement => {

  return (
    <div className={'siteContainer'}>
      <Container>
        <Row>
          <Col md={12}>
            <div className={'headline'}>Die Kleine Galerie Hans Nadler ist für Sie geöffnet von:</div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className={'infoText'}>
            <div>Dienstag bis Sonntag 14 bis 17 Uhr</div>
            <div>oder nach Vereinbarung</div>
            <div>Montag geschlossen</div>
          </Col>
        </Row>
        <Row>
          <Col md={4}><Image src={'./Karte.png'}
                             className={'artistImage'}
                             alt={'Karte Gallerie Nadler'}/></Col>
          <Col md={8}>
            <div className={'headline'}>Anreise</div>
            <div className={'infoText'}>Von hier nach dort und nicht über Da-Nicht-Lang/NL.</div>
            <div className={'infoText'}>
              <div>Hauptstraße 29</div>
              <div>Elsterwerda, Brandenburg 04910 Deutschland</div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default Location;