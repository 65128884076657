import {ReactElement} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";


const Events2022 = (): ReactElement => {

  return (
    <>
      <div className={'siteContainer'}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={'headline'}>AUSSTELLUNGSPLAN NADLER GALERIE 2022</div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div>&nbsp;</div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className={'headline'}>14.01.-16.02. Elsterschloss Gymnasium</div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={'infoText'}>

            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Link to={'./stephanie'} className={'externalLink'}><div className={'headline'}>19.02.-28.04. Stephanie Mai</div></Link>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={'infoText'}>
              <Link to={'./stephanie'} className={'externalLink'}><div>"Der Mensch in seiner Natur“ - Pinsel und Kettensäge</div></Link>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Link to={'./luise'} className={'externalLink'}><div className={'headline'}>30.04.-07.07. Luise Walter</div></Link>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={'infoText'}>
              <Link to={'./luise'} className={'externalLink'}><div>"Das entschwundene Land“- Malerei und Skulptur</div></Link>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Link to={'./steffen'} className={'externalLink'}><div className={'headline'}>09.07.-15.09. Steffen Mertens</div></Link>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={'infoText'}>
              <div>Variationen zu: Wer bin ich und wenn ja, wie viele? <div style={{fontSize: 'x-small'}}>Buchtitel: Richard David Precht</div></div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div className={'headline'}>17.09.-24.11. Gruppenausstellung Werenzhain</div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={'infoText'}>
              <div>„STILL LEBEN - Nature morte“ 5Künstlerinnen</div>
              <div>(R.Baumeister/ U.Bierther/ L.Landsberg/ A.Scholz/ I.Stöber)</div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className={'headline'}>24.06. 19:00 Uhr Eine Lesung mit Udo Tiffert</div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={'infoText'}>
            </Col>
          </Row>

        </Container>
      </div>
    </>
  )
}
export default Events2022;