import {Col, Container, Image, Row} from "react-bootstrap";
import {PhotoSwipeGallery} from "react-photoswipe";
import {AppUrl} from "../../../../../types/AppUrl";

export const JensPuppe = () => {

  let items: any[] = [
    {
      src: AppUrl.getBaseUrl() + '/img/2023/jens_puppe/image0.jpg', thumbnail: AppUrl.getBaseUrl() + '/img/2023/jens_puppe/thumbnail/image0.jpg',
      w: 2230, h: 3122,
      title: '<div class="imageCaption fadeIn"><div>Weite Wege</div><div>Öl auf Pappe</div><div>110x140cm</div><div>2023</div></div>'
    },
    {
      src: AppUrl.getBaseUrl() + '/img/2023/jens_puppe/image1.jpg', thumbnail: AppUrl.getBaseUrl() + '/img/2023/jens_puppe/thumbnail/image1.jpg',
      w: 1923, h: 3384,
      title: '<div class="imageCaption fadeIn"><div>Spiegelung</div><div>Öl auf Pappe</div><div>100x170cm</div><div>2023</div></div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2023/jens_puppe/image2.jpg', thumbnail: AppUrl.getBaseUrl() + '/img/2023/jens_puppe/thumbnail/image2.jpg',
      w: 3528, h: 1230,
      title: '<div class="imageCaption fadeIn"><div>Durch Wald und Flur</div><div>Öl auf Hartfaserplatte</div><div>180x60cm</div><div>2022</div></div>'
    },{
      src: AppUrl.getBaseUrl()+'/img/2023/jens_puppe/image3.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2023/jens_puppe/thumbnail/image3.jpg',
      w: 1926, h: 1923,
      title: '<div class="imageCaption fadeIn"><div>nicht in der Ausstellung!</div><div></div><div></div><div></div></div>'
    },{
      src: AppUrl.getBaseUrl()+'/img/2023/jens_puppe/image4.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2023/jens_puppe/thumbnail/image4.jpg',
      w: 1905, h: 2576,
      title: '<div class="imageCaption fadeIn"><div>nicht in der Ausstellung!</div><div></div><div></div><div></div></div>'
    },{
      src: AppUrl.getBaseUrl()+'/img/2023/jens_puppe/image5.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2023/jens_puppe/thumbnail/image5.jpg',
      w: 1563, h: 2219,
      title: '<div class="imageCaption fadeIn"><div>Gnadenbrotkoppel</div><div>Öl auf Leinwand</div><div>70x100cm</div><div>2022</div></div>'
    },{
      src: AppUrl.getBaseUrl()+'/img/2023/jens_puppe/image_1.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2023/jens_puppe/thumbnail/image_1.jpg',
      w: 1365, h: 1008,
      title: '<div class="imageCaption fadeIn"><div>Der Traum</div><div>Öl auf Leinwand</div><div>200x140cm</div><div>2023</div></div>'
    },{
      src: AppUrl.getBaseUrl()+'/img/2023/jens_puppe/image_2.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2023/jens_puppe/thumbnail/image_2.jpg',
      w: 889, h: 1280,
      title: '<div class="imageCaption fadeIn"><div>Mutter und Kind</div><div>Öl auf Leinwand</div><div>70x100cm</div><div>2023</div></div>'
    },{
      src: AppUrl.getBaseUrl()+'/img/2023/jens_puppe/image_3.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2023/jens_puppe/thumbnail/image_3.jpg',
      w: 1271, h: 1280,
      title: '<div class="imageCaption fadeIn"><div>Idyll</div><div>Öl auf Leinwand</div><div>100x100cm</div><div>2023</div></div>'
    },{
      src: AppUrl.getBaseUrl()+'/img/2023/jens_puppe/JP_01.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2023/jens_puppe/thumbnail/JP_01.jpg',
      w: 1008, h: 1344,
      title: '<div class="imageCaption fadeIn"><div>Tanzender</div><div>Metall</div><div></div><div>2017</div></div>'
    }
  ];

  let options = {
    history: false,
    focus: false,
    showAnimationDuration: 600,
    hideAnimationDuration: 600,
    closeOnScroll: false,
    shareEl: false,
    fullscreenEl: false,
    addCaptionHTMLFn: function (item: any, captionEl: any, isFake: boolean) {
      // item      - slide object
      // captionEl - caption DOM element
      // isFake    - true when content is added to fake caption container
      //             (used to get size of next or previous caption)

      if (!item.title) {
        captionEl.children[0].innerHTML = '';
        return false;
      }
      captionEl.children[0].innerHTML = item.title;
      return true;
    }
  };

  const getThumbnailContent = (item: any) => {
    return (
      <img src={item.thumbnail} alt={item.title} style={{margin: '3px'}}/>
    );
  }

  return (
    <div className={'siteContainer'}>
      <Container>
        <Row>
          <Col md={12}>
            <div className={'eventPeriod'}>23.04.-29.06.2023, Galeriegespräch am 06.05.2023 um 19:00 Uhr</div>
            <div className={'eventPeriod'}>ICH SEHE WAS, WAS DU NICHT SIEHST</div>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Image src={AppUrl.getBaseUrl() + '/img/2023/jens_puppe/JensPuppe.jpg'}
                   className={'artistImage '}
                   alt={'Jens Puppe'}
            />
            <div className={'artistImageText'}>© Jens Puppe</div>
          </Col>
          <Col md={8}>
            <div className={'artistName'}>Jens Puppe</div>
            <div className={'artistBio'}>
              JENS PUPPE - lebt und wirkt mit seinem Schaffen „Jenseits der Linie“<br/>
              In Finsterwalde 1976 geboren wuchs er in zwei unterschiedlichen Gesellschaftssystemen auf, bekam so früh Aufbruch und Umbruch mit. Zwei Dinge die bis heute sein Leben beeinflussen. Nach dem Abitur ging er nach Berlin, um Geoingenieurswissenschaften zu studieren – ließ sich aber in dieser Zeit von der pulsierenden, kreativen Großstadt inspirieren. Tauchte zum ersten Mal in die Kunstszene ein, die ihn nie wieder loslassen sollte. Und so prägte jede weitere Lebensstation, wie die in Österreich, Madeira oder der Aida als Barkeeper, in Leipzig als Sportstudent, als Reisender durch Spanien oder in Finsterwalde als Kultur Café -Inhaber seine künstlerische Vielfalt.
              <br/><br/>
              Zum Malen kam der junge Puppe durch einen tief empfundenen Schmerz. Die erste große Liebe seines Lebens zerbrach und um diesem Gefühl der Trauer und Einsamkeit Ausdruck zu geben, griff er zur Farbe und zum Pinsel. Was folgte war ein künstlerisches Aus-probieren, ein Zusammenschluss mit anderen Künstlern und der unbändige Wille in die Sängerstadt Finsterwalde einer alternativen und kreativen Szene Aufmerksamkeit zu geben. So entstanden Ausstellungen wie „MORPH“ und die Galerie „Grenouille“.  Mit einer eigenen Ausstellung: „Die Made hält ihren Käse für die Welt“ in der Finsterwalder Sparkassengalerie begeisterte er ein großes Publikum. Seitdem folgten kleinere und größere Projekte in der Stadt Finsterwalde.
              2010 ging er dann noch einmal für ein künstlerisches Studium nach Greifswald – brach dieses jedoch nach zwei Jahren ab – gründete eine Familie und wurde nun dauerhaft in Finsterwalde sesshaft.
              Heute arbeitet er als Kunstlehrer an verschiedenen Schulen, ist Bildhauer und Maler. Seine Werke zeichnen sich durch eine einzigartige Handschrift aus. Oft sind es dunkle Farben, die den Schmerz dieser Welt mit Hoffnung starken farblichen Elementen verbinden, die den Betrachter spüren lassen, dass Puppe „Jenseits der Linie“ nicht nur malt, sondern das auch lebt: nicht linientreu, immer etwas rebellisch, immer im Um- und Aufbruch.
              <br/><div className={'artistImageText'}>Text: Angela Krüger Hesse</div>
            </div>
            {/*<div className={"additionalInfos"}>Im Internet:<br/>
              <a href={"http://www.steffen-mertens.de"} className={'externalLink'}>www.steffen-mertens.de</a>
            </div>*/}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <PhotoSwipeGallery items={items} options={options} thumbnailContent={getThumbnailContent}/>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
