import {FunctionComponent, ReactElement} from "react";
import 'react-photoswipe/lib/photoswipe.css';
import {GudrunBroechlerNeumann} from "./Archive/Artists/2024/GudrunBroechlerNeumann";


const Event: FunctionComponent = (): ReactElement => {
  return (
    <GudrunBroechlerNeumann/>
  );
}
export default Event;
