import {FunctionComponent, ReactElement} from "react";
import {Col, Container, Image, Row} from "react-bootstrap";
import {PhotoSwipeGallery} from "react-photoswipe";
import 'react-photoswipe/lib/photoswipe.css';
import {AppUrl} from "../../../../../types/AppUrl";

const ArtistStephanieMai: FunctionComponent = (): ReactElement => {

  let items = [
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/Angekommen_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/Angekommen_klein.jpg',
      w: 969, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Angekommen</div><div>Acryl auf Leinwand</div><div>40 x 50cm</div><div>2014</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/Entlandet_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/Entlandet_klein.jpg',
      w: 877, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Entlandet</div><div>Öl auf Leinwand</div><div>50 x 70cm</div><div>2015</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/Dahin_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/Dahin_klein.jpg',
      w: 850, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Dahin</div><div>Acryl auf Leinwand</div><div>50 x 70cm</div><div>2011</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/Spiel_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/Spiel_klein.jpg',
      w: 841, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Spiel</div><div>Acryl auf Leinwand</div><div>34 x 52cm</div><div>2011</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/Hase_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/Hase_klein.jpg',
      w: 1200, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Hase</div><div>Öl auf Leinwand</div><div>20 x 20cm</div><div>2015</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/FloraI_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/FloraI_klein.jpg',
      w: 1200, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Flora I</div><div>Acryl auf Leinwand</div><div>60 x 60cm</div><div>2011</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/FloraII_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/FloraII_klein.jpg',
      w: 1200, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Flora II</div><div>Acryl auf Leinwand</div><div>60 x 60cm</div><div>2012</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/FloraIII_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/FloraIII_klein.jpg',
      w: 1200, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Flora III</div><div>Acryl auf Leinwand</div><div>60 x 60cm</div><div>2011</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/FloraIV_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/FloraIV_klein.jpg',
      w: 1200, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Flora IV</div><div>Acryl auf Leinwand</div><div>60 x 60cm</div><div>2020</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/Artemisia_vulgaris_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/Artemisia_vulgaris_klein.jpg',
      w: 843, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Artemesia vulgaris</div><div>Öl auf Leinwand</div><div>70 x 100cm</div><div>2019</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/Proschwitz_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/Proschwitz_klein.jpg',
      w: 839, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Proschwitz</div><div>Öl auf Leinwand</div><div>100 x 70cm</div><div>2011</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/Domo_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/Domo_klein.jpg',
      w: 1763, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Domo</div><div>Acryl auf Leinwand</div><div>140 x 200cm</div><div>2019</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/warm_kalt_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/warm_kalt_klein.jpg',
      w: 1711, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Warmkalt</div><div>Acryl auf Leinwand</div><div>70 x 100cm</div><div>2017</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/Schlaf_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/Schlaf_klein.jpg',
      w: 1447, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Schlaf</div><div>Acryl auf Leinwand</div><div>120 x 100cm</div><div>2022</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/Mono_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/Mono_klein.jpg',
      w: 858, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Mono</div><div>Acryl auf Leinwand</div><div>140 x 100 cm</div><div>2021</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/Renatur_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/Renatur_klein.jpg',
      w: 891, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Renatur</div><div>Acryl auf Leinwand</div><div>140 x 100 cm</div><div>2021</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/Simpel_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/Simpel_klein.jpg',
      w: 866, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Simpel</div><div>Acryl auf Leinwand</div><div>140 x 100 cm</div><div>2021</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/ICONIII_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/ICONIII_klein.jpg',
      w: 1388, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Icon III</div><div>Öl auf Leinwand</div><div>100 x 120cm</div><div>2018</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/NeumannII_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/NeumannII_klein.jpg',
      w: 958, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Neumann II</div><div>Öl auf Leinwand</div><div>100 x 80cm</div><div>2019</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/Procul_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/Procul_klein.jpg',
      w: 847, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Procul</div><div>Öl auf Leinwand</div><div>140 x 100 cm</div><div>2022</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/WaldI_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/WaldI_klein.jpg',
      w: 803, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Wald I</div><div>Mixed Media auf Leinwand</div><div>180 x 120cm</div><div>2021</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/KraftII_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/KraftII_klein.jpg',
      w: 796, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Kraft II</div><div>Öl auf Leinwand</div><div>180 x 120cm</div><div>2020</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/equus_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/equus_klein.jpg',
      w: 700, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Equus</div><div>Kiefernholz geölt</div><div>H 70 B 30 T40</div><div>2021</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/Minotauros_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/Minotauros_klein.jpg',
      w: 700, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Minotauros</div><div>Kiefernholz geölt</div><div>H 80 B 30 T30</div><div>2021</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/Bildung_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/Bildung_klein.jpg',
      w: 543, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Bildung</div><div>Kiefernholz gefaßt in mixed Media</div><div>H 115 B 30 T30</div><div>2021</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/Retro_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/Retro_klein.jpg',
      w: 700, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Retro</div><div>Kiefernholz gefaßt in mixed Media</div><div>H 70 B 30 T40</div><div>2020</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/Retro_kleinII.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/Retro_kleinII.jpg',
      w: 700, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Retro</div><div>Kiefernholz gefaßt in mixed Media</div><div>H 70 B 30 T40</div><div>2020</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/Hüter_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/Hüter_klein.jpg',
      w: 543, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Hüter</div><div>Kiefernholz gefaßt in mixed Media</div><div>H 70 B 35 T35</div><div>2021</div></div>'
    },
    {
      src: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/Relikt_klein.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2022/stephaniemai/thumbnail/Relikt_klein.jpg',
      w: 543, h: 1200,
      title: '<div class="imageCaption fadeIn"><div>Relikt</div><div>Kiefernholz geölt</div><div>H 65 B 30 T25</div><div>2021</div></div>'
    },


  ];

  let options = {
    history: false,
    focus: false,
    showAnimationDuration: 600,
    hideAnimationDuration: 600,
    closeOnScroll: false,
    shareEl: false,
    fullscreenEl: false,
    addCaptionHTMLFn: function (item: any, captionEl: any, isFake: boolean) {
      // item      - slide object
      // captionEl - caption DOM element
      // isFake    - true when content is added to fake caption container
      //             (used to get size of next or previous caption)

      if (!item.title) {
        captionEl.children[0].innerHTML = '';
        return false;
      }
      captionEl.children[0].innerHTML = item.title;
      return true;
    }
  };

  const getThumbnailContent = (item: any) => {
    return (
      <img src={item.thumbnail} alt={item.title} style={{margin: '3px'}}/>
    );
  }

  return (
    <div className={'siteContainer'}>
      <Container>
        <Row>
          <Col md={12}>
            <div className={'eventPeriod'}>19.02.2022 – 28.04.2022, Galeriegespräch am 04.03.2022 um 19:00 Uhr</div>
            <div className={'eventPeriod'}>„Der Mensch in seiner Natur“ - mit Pinsel und Kettensäge</div>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Image src={AppUrl.getBaseUrl()+'/img/2022/stephaniemai/StephanieMai.png'}
                   className={'artistImage '}
                   alt={'Stephanie Mai'}/>
            <div className={'artistImageText'}>© Jule Kaiser</div>
          </Col>
          <Col md={8}>
            <div className={'artistName'}>Stephanie Mai</div>
            <div className={"additionalInfos"}>Malerin / Musikerin / Diplomrestauratorin</div>
            <div className={'artistBio'}>
              Stephanie Mai alias sankt ruben wurde 1980 in Finsterwalde, Brandenburg, geboren.
              Bereits im Alter von 13 Jahren arbeitet sie erfolgreich als Songwriterin und Musikerin in
              Meißen. Durch ihre Mutter, eine Meißner Porzellanmalerin, erlernt sie früh das Handwerk
              der Malerei. Nach dem Abitur zieht Ruben nach Berlin und widmet sich der Musik und der
              Malerei. Sie arbeitet mit namhaften bildenden Künstlern zusammen und assistiert im
              Atelier von Juan Miguel Pozo Cruz (Kuba), er unterrichtet sie dafür in der Malerei. Sie hat
              zahlreiche Ausstellungen in Berlin und anderen deutschen Städten und gründet mit
              anderen Berliner Künstlern den Verein „sollbruch e.V.“.<br/>
              Das Interesse an historischen Techniken führt sie zum Studium der Restaurierung, Ruben
              erhält das Diplom für Wandmalerei und farbige Architekturfassungen.<br/>
              Auf der Suche nach einem Ort der Konzentration zieht St. Ruben 2013 in ein
              abgeschiedenes Bauernhaus in Gröbitz bei Finsterwalde. Dort widmet sie sich der Kunst,
              gibt Malkurse, erledigt Auftragsarbeiten und führt Fassaden- und Wandmalereien aus.
            </div>
            <div className={"additionalInfos"}>Im Internet:<br/>
              <a href={"http://www.kunstamraum.com"} className={'externalLink'}>www.kunstamraum.com</a> / <a href={'https://www.sanktruben.com'} className={'externalLink'}>www.sanktruben.com</a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <PhotoSwipeGallery items={items} options={options} thumbnailContent={getThumbnailContent}/>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default ArtistStephanieMai;
