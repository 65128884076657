import {Col, Container, Image, Row} from "react-bootstrap";
import {PhotoSwipeGallery} from "react-photoswipe";
import {AppUrl} from "../../../../../types/AppUrl";

export const GudrunBroechlerNeumann = () => {

  let items: any[] = [
    {
      src: AppUrl.getBaseUrl() + '/img/2024/gudrun_broechler-neumann/1.JPG',
      thumbnail: AppUrl.getBaseUrl() + '/img/2024/gudrun_broechler-neumann/thumbnail/1.JPG',
      w: 950,
      h: 803,
      title: '<div class="imageCaption fadeIn"><div>Foto: © Jana Zadow-Dorr</div>'
    },
    {
      src: AppUrl.getBaseUrl() + '/img/2024/gudrun_broechler-neumann/2.JPG',
      thumbnail: AppUrl.getBaseUrl() + '/img/2024/gudrun_broechler-neumann/thumbnail/2.JPG',
      w: 582,
      h: 643,
      title: '<div class="imageCaption fadeIn"><div>Foto: © Jana Zadow-Dorr</div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2024/gudrun_broechler-neumann/3.JPG',
      thumbnail: AppUrl.getBaseUrl() + '/img/2024/gudrun_broechler-neumann/thumbnail/3.JPG',
      w: 898,
      h: 788,
      title: '<div class="imageCaption fadeIn"><div>Foto: © Jana Zadow-Dorr</div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2024/gudrun_broechler-neumann/4.JPG',
      thumbnail: AppUrl.getBaseUrl() + '/img/2024/gudrun_broechler-neumann/thumbnail/4.JPG',
      w: 1023,
      h: 802,
      title: '<div class="imageCaption fadeIn"><div>Foto: © Jana Zadow-Dorr</div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2024/gudrun_broechler-neumann/5.JPG',
      thumbnail: AppUrl.getBaseUrl() + '/img/2024/gudrun_broechler-neumann/thumbnail/5.JPG',
      w: 988,
      h: 803,
      title: '<div class="imageCaption fadeIn"><div>Foto: © Jana Zadow-Dorr</div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2024/gudrun_broechler-neumann/6.JPG',
      thumbnail: AppUrl.getBaseUrl() + '/img/2024/gudrun_broechler-neumann/thumbnail/6.JPG',
      w: 1200,
      h: 803,
      title: '<div class="imageCaption fadeIn"><div>Foto: © Jana Zadow-Dorr</div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2024/gudrun_broechler-neumann/7.JPG',
      thumbnail: AppUrl.getBaseUrl() + '/img/2024/gudrun_broechler-neumann/thumbnail/7.JPG',
      w: 1200,
      h: 803,
      title: '<div class="imageCaption fadeIn"><div>Foto: © Jana Zadow-Dorr</div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2024/gudrun_broechler-neumann/8.JPG',
      thumbnail: AppUrl.getBaseUrl() + '/img/2024/gudrun_broechler-neumann/thumbnail/8.JPG',
      w: 1200,
      h: 803,
      title: '<div class="imageCaption fadeIn"><div>Foto: © Jana Zadow-Dorr</div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2024/gudrun_broechler-neumann/9.JPG',
      thumbnail: AppUrl.getBaseUrl() + '/img/2024/gudrun_broechler-neumann/thumbnail/9.JPG',
      w: 1200,
      h: 803,
      title: '<div class="imageCaption fadeIn"><div>Foto: © Jana Zadow-Dorr</div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2024/gudrun_broechler-neumann/10.JPG',
      thumbnail: AppUrl.getBaseUrl() + '/img/2024/gudrun_broechler-neumann/thumbnail/10.JPG',
      w: 1200,
      h: 803,
      title: '<div class="imageCaption fadeIn"><div>Foto: © Jana Zadow-Dorr</div>'
    }
  ];

  let options = {
    history: false,
    focus: false,
    showAnimationDuration: 600,
    hideAnimationDuration: 600,
    closeOnScroll: false,
    shareEl: false,
    fullscreenEl: false,
    addCaptionHTMLFn: function (item: any, captionEl: any, isFake: boolean) {
      // item      - slide object
      // captionEl - caption DOM element
      // isFake    - true when content is added to fake caption container
      //             (used to get size of next or previous caption)

      if (!item.title) {
        captionEl.children[0].innerHTML = '';
        return false;
      }
      captionEl.children[0].innerHTML = item.title;
      return true;
    }
  };

  const getThumbnailContent = (item: any) => {
    return (
        <img src={item.thumbnail} alt={item.title} style={{margin: '3px'}}/>
    );
  }

  return (
      <div className={'siteContainer'}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={'eventPeriod'}>05.09.2024 - 07.11.2024, Galeriegespräch am 20.09.2024 um 19:30 Uhr</div>
              <div className={'eventPeriod'}>„Tochter’s Lieblingsstücke“ - ausgewählte Werke aus Nachlass</div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Image src={AppUrl.getBaseUrl() + '/img/2024/gudrun_broechler-neumann/gbn.jpg'}
                     className={'artistImage '}
                     alt={'Gudrun Broechler-Neumann'}
              />
              <div className={'artistImageText'}>© Frank Ratajczak</div>
            </Col>
            <Col md={8}>
              <div className={'artistName'}>Gudrun Bröchler-Neumann</div>
              <div className={'artistBio'}>
                <div>
                  geboren am 30. Juni 1937 in Freital-Hainsberg<br/>
                  verstorben am 18. August 2013 in Finsterwalde<br/>
                  <br/>
                  <u>Wirkungsorte:</u> Lübbenau, Cottbus, Sellendorf, Kreta <br/>
                  <br/>
                  <u>Biografie:</u><br/>
                  <table aria-description={'Biografie'}>
                    <tbody>
                    <tr>
                      <td className="table-cell">1953 bis 1955</td>
                      <td className="table-cell">
                        Lehre als Porzellanmalerin in der<br/>
                        Porzelline Freital-Potschappel (Thieme)
                      </td>
                    </tr>
                    <tr>
                      <td className="table-cell">1956 bis 1961</td>
                      <td className="table-cell">
                        Studium an der Hochschule für Bildende Künste bei<br/>
                        Prof. Fraaß und Prof. Lohmar (Wandmalerei) in Dresden
                      </td>
                    </tr>
                    <tr>
                      <td className="table-cell">1961 bis 2013</td>
                      <td className="table-cell">
                        freischaffend tätig, davon: 1961 bis 1970 in Lübbenau <br/> 1970 bis 1976 in Cottbus<br/>
                        1976 bis 2013 in Sellendorf <br/> von 1993 bis 2010 regelmäßig auf Kreta
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <br/>
                  <u>Studienreise:</u> Bulgarien, u. a. Targowischte; Griechenland - Kreta; Russland - Leningrad, Moskau,
                  Tjumen und Nowosibirsk; Frankreich - Paris; Tschechien - Prag; Polen<br/><br/>
                </div>
                <div>
                  <u>Ausstellungen:</u><br/>
                  <div id={'listExhibitions'}>
                    <div>
                      <b>Einzelausstellungen (Stand 2024): </b>
                      Brandenburg a.d.H. (1979),
                      Cottbus (1971, 1978, 1983, 2015*),
                      Dahme (1978, 1983, 1996),
                      Dresden (1993),
                      Elsterwerda (2024*),
                      Freital (1987),
                      Glashütte, Galerie am Packschuppen (2003, 2021*),
                      Golßen (1975, 1977),
                      Groß Leuthen (2018*),
                      Königs Wusterhausen (2019*),
                      Luckenwalde (2007),
                      Lübben (1998, 2001),
                      Lübbenau (1968, 1987),
                      Massen (2016*)
                    </div><br/>
                    <div>
                      <b>Ausstellungsbeteiligungen: </b>
                      Berlin (1986),
                      Cottbus, Bezirkskunstausstellungen (1963 bis 1986),
                      Dresden, V. und VI. Kunstausstellung (1962, 1972),
                      Freital, Haus der Heimat/Schloss Burgk (1970, 2021*),
                      Glashütte, Galerie am Packschuppen,
                      Grosseto/Italien (1976),
                      Heraklion/Kreta (1997),
                      Motzen (1998),
                      Leipzig, Ausstellung der GEDOK (1997),
                      Oranienburg, Ausstellung der GEDOK (1996),
                      Poznan/Polen (1976),
                      Targowischte/Bulgarien (1977),
                      Tjumen/Russland (1986),
                      Potsdam (1979, 2024* VPK-Jahresausstellung im Landtag),
                      Prettin (1990),
                      Rabenau (2017*),
                      Rangsdorf (1996),
                      Seelow (1982),
                      Senftenberg (1993, Ende 2013 bis Anfang 2014*, 2021-2022*),
                      Wittenberg (1989),
                      Zossen (1997)
                    </div><br/>
                    <div>
                      <b>Baugebundene Kunst, davon noch erhalten: </b>
                      1961 Oberschule Dresden, Zirkusstraße (Scagliola),
                      1974 Oberschule Luckau (Schnittkeramik/Naturstein),
                      1978 VEG Leuthen (Scaciola),
                      1984 Oberschule Burg/Spreewald (Keramikmosaik),
                      1984 Oberschule Finsterwalde-Nord (Keramikmosaik/Natursteine)
                    </div><br/>
                    <div>
                      <b>zwei Fotoausstellungen </b>
                    </div><br/>
                    <div>
                      <b>Buchveröffentlichung: </b>
                      „Der Anemonenhügel und Anderes auf meinem Weg“, BOD 2008
                    </div>
                    <br/>
                    <div>
                      * Die Ausstellungen nach dem Tode von GBN werden von ihrer
                      Tochter Jana Zadow-Dorr organisiert und kuratiert.
                    </div>
                  </div>
                </div>
              </div>
              <div className={"additionalInfos"}>weitere Informationen:<br/>

                <a href={"https://private-kuenstlernachlaesse-brandenburg.de/person/9"}
                   className={'externalLink'}>private-kuenstlernachlaesse-brandenburg.de</a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <PhotoSwipeGallery items={items} options={options} thumbnailContent={getThumbnailContent}/>
            </Col>
          </Row>
        </Container>
      </div>
  )
}
