import {FunctionComponent, ReactElement} from "react";
import {Col, Container, Image, Row} from "react-bootstrap";
import {PhotoSwipeGallery} from "react-photoswipe";
import 'react-photoswipe/lib/photoswipe.css';
import {AppUrl} from "../../../../../types/AppUrl";


const ArtistSteffenMertens: FunctionComponent = (): ReactElement => {

  let items = [
    {
      src: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/Lesender.jpg', thumbnail: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/thumbnail/Lesender.jpg',
      w: 2437, h: 3221,
      title: '<div class="imageCaption fadeIn"><div>Lesender</div><div>Sepiamalerei und Zeichnung auf Chinapapier</div><div>80 x 60 cm</div><div>um 2015</div></div>'
    },
    {
      src: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/WerBinIch.jpg', thumbnail: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/thumbnail/WerBinIch.jpg',
      w: 1200, h: 1600,
      title: '<div class="imageCaption fadeIn"><div>Wer bin ich ...(Ausstellungsplakat) </div><div>Federzeichnung auf Eindruckplakat</div><div>90 x 70 cm</div><div>2022</div></div>'
    },
    {
      src: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/72Varianten.jpg', thumbnail: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/thumbnail/72Varianten.jpg',
      w: 2590, h: 1812,
      title: '<div class="imageCaption fadeIn"><div>72 Varianten in einer immer gleichen Grundform zum Thema "Wer bin ich"</div><div>jeweils neun Collagen auf acht Tafeln</div><div>im Rahmen 100 x 140 cm</div><div>2020/22</div></div>'
    },
    {
      src: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/18_Portraet.jpg', thumbnail: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/thumbnail/18_Portraet.jpg',
      w: 2318, h: 3088,
      title: '<div class="imageCaption fadeIn"><div>Wer bin ich ...</div><div>Tuschmalerei auf Chinapapier</div><div>70 x 50 cm</div><div>2018</div></div>'
    },
    {
      src: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/TorsoMitVenezianischerMaske.jpg', thumbnail: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/thumbnail/TorsoMitVenezianischerMaske.jpg',
      w: 2240, h: 4608,
      title: '<div class="imageCaption fadeIn"><div>Torso mit venezianischer Maske<div>Detail aus der Figurengruppe zum Thema Wer bin ich ...</div></div><div>Lindenholz</div><div>76 x 23 x 23 cm<br/>Sockel: 80 x 30 x 30 cm</div><div>o. J.</div></div>'
    },
    {
      src: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/div-Frauen-Maennerkoepfe.jpg', thumbnail: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/thumbnail/div-Frauen-Maennerkoepfe.jpg',
      w: 2212, h: 2934,
      title: '<div class="imageCaption fadeIn"><div>Figurengruppe zum Thema Wer bin ich ...</div><div>verschiedene Materialien und Größen</div><div></div><div>2016-2022</div></div>'
    },
    {
      src: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/Drahtplastik.jpg', thumbnail: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/thumbnail/Drahtplastik.jpg',
      w: 1200, h: 1600,
      title: '<div class="imageCaption fadeIn"><div>Drahtplastik, Rot</div><div>105 x 60 x 30 cm</div><div>Sockel: 122 x 20 x 20 cm</div><div>um 2000</div></div>'
    },
    {
      src: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/OT_1.jpg', thumbnail: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/thumbnail/OT_1.jpg',
      w: 1200, h: 1600,
      title: '<div class="imageCaption fadeIn"><div>Na und ?</div><div>Acrylfarbe auf Maltuch</div><div>110 x 90 cm</div><div>2019</div></div>'
    },
    {
      src: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/OT_2.jpg', thumbnail: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/thumbnail/OT_2.jpg',
      w: 1200, h: 1600,
      title: '<div class="imageCaption fadeIn"><div>Wer bin ich ...</div><div>Naturpapier, Schwarzgrau, Weiß auf Drahtmatte, Acrylschwarz, weiße Kreide</div><div>150 x 100 cm</div><div>2019</div></div>'
    },
    {
      src: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/OT_3.jpg', thumbnail: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/thumbnail/OT_3.jpg',
      w: 1200, h: 1600,
      title: '<div class="imageCaption fadeIn"><div>Wer bin ich ...</div><div>Acrylfarbe auf Alublech</div><div>100 x 70 cm</div><div>2021</div></div>'
    },
    {
      src: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/OT_4.jpg', thumbnail: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/thumbnail/OT_4.jpg',
      w: 1200, h: 1600,
      title: '<div class="imageCaption fadeIn"><div>Wer bin ich ...</div><div>Acrylfarbe auf Alublech</div><div>100 x 70 cm</div><div>2021</div></div>'
    },
    {
      src: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/OT_5.jpg', thumbnail: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/thumbnail/OT_5.jpg',
      w: 835, h: 1002,
      title: '<div class="imageCaption fadeIn"><div>Wer bin ich ...</div><div>Tuschmalerei auf Chinapapier, auf Maltuch kaschiert</div><div>60 x 50 cm</div><div>2020</div></div>'
    },
    {
      src: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/OT_6.jpg', thumbnail: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/thumbnail/OT_6.jpg',
      w: 1503, h: 2133,
      title: '<div class="imageCaption fadeIn"><div>Wer bin ich ...</div><div>Tuschzeichnung, Deckweiß, Stempeldrucke auf getöntem Papier</div><div>50 x 40 cm</div><div>um 2015</div></div>'
    },
    {
      src: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/OT_7.jpg', thumbnail: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/thumbnail/OT_7.jpg',
      w: 984, h: 1377,
      title: '<div class="imageCaption fadeIn"><div>Wer bin ich ...</div><div>Malerei auf Naturpapier und Bleistiftzeichnung</div><div>gerahmt: 30 x 20 cm</div><div>um 2015</div></div>'
    },
    {
      src: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/OT_8.jpg', thumbnail: AppUrl.getBaseUrl() + '/img/2022/steffenmertens/thumbnail/OT_8.jpg',
      w: 1200, h: 1600,
      title: '<div class="imageCaption fadeIn"><div>Werkstattfoto</div><div></div><div></div><div>2022</div></div>'
    },

  ];

  let options = {
    history: false,
    focus: false,
    showAnimationDuration: 600,
    hideAnimationDuration: 600,
    closeOnScroll: false,
    shareEl: false,
    fullscreenEl: false,
    addCaptionHTMLFn: function (item: any, captionEl: any, isFake: boolean) {
      // item      - slide object
      // captionEl - caption DOM element
      // isFake    - true when content is added to fake caption container
      //             (used to get size of next or previous caption)

      if (!item.title) {
        captionEl.children[0].innerHTML = '';
        return false;
      }
      captionEl.children[0].innerHTML = item.title;
      return true;
    }
  };

  const getThumbnailContent = (item: any) => {
    return (
      <img src={item.thumbnail} alt={item.title} style={{margin: '3px'}}/>
    );
  }

  return (
    <div className={'siteContainer'}>
      <Container>
        <Row>
          <Col md={12}>
            <div className={'eventPeriod'}>09.07.2022 – 15.09.2022, Galeriegespräch am 29.07.2022 um 19:00 Uhr</div>
            <div className={'eventPeriod'}>Variationen zu: Wer bin ich und wenn ja, wie viele? <div style={{fontSize: 'x-small'}}>Buchtitel: Richard David Precht</div></div>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Image src={AppUrl.getBaseUrl() + '/img/2022/steffenmertens/SteffenMertens.jpg'}
                   className={'artistImage '}
                   alt={'Steffen Mertens'}/>
            <div className={'artistImageText'}>© Steffen Mertens</div>
          </Col>
          <Col md={8}>
            <div className={'artistName'}>Steffen Mertens</div>
            <div className={'artistBio'}>
              1943 in Rathenow/Havel geboren <br/>
              1964-1968 Grafikstudium an der Kunsthochschule Berlin-Weißensee<br/>
              1972-1973 Studium an der Fachschule für Werbung und Gestaltung Berlin-Schöneweide<br/>
              1973 Hinwendung zur Bildhauerei<br/>
              Ab 1974 freischaffend als Bildhauer tätig<br/>
              1990-1992 Theaterplastiker am Staatstheater Cottbus<br/>
              Seit 1992 wieder freischaffend in Cottbus<br/>
            </div>
            <div className={"additionalInfos"}>Im Internet:<br/>
              <a href={"http://www.steffen-mertens.de"} className={'externalLink'}>www.steffen-mertens.de</a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <PhotoSwipeGallery items={items} options={options} thumbnailContent={getThumbnailContent}/>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default ArtistSteffenMertens;
