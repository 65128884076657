export enum WindowBreakpoint {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl'
}

class WindowBreakpointUtils {

  // equal to bootstrap breakpoints
  static breakpoints: Record<WindowBreakpoint, number> = {
    [WindowBreakpoint.XS]: 0,
    [WindowBreakpoint.SM]: 576,
    [WindowBreakpoint.MD]: 768,
    [WindowBreakpoint.LG]: 992,
    [WindowBreakpoint.XL]: 1200
  };

  static calculateCurrentWindowBreakpoint = (): WindowBreakpoint => {
    const windowWidth = window.innerWidth;
    let windowBreakpoint = WindowBreakpoint.XS;
    switch (true) {
      case windowWidth >= WindowBreakpointUtils.breakpoints[WindowBreakpoint.XL]:
        windowBreakpoint = WindowBreakpoint.XL;
        break;
      case windowWidth >= WindowBreakpointUtils.breakpoints[WindowBreakpoint.LG]:
        windowBreakpoint = WindowBreakpoint.LG;
        break;
      case windowWidth >= WindowBreakpointUtils.breakpoints[WindowBreakpoint.MD]:
        windowBreakpoint = WindowBreakpoint.MD;
        break;
      case windowWidth >= WindowBreakpointUtils.breakpoints[WindowBreakpoint.SM]:
        windowBreakpoint = WindowBreakpoint.SM;
        break;
    }
    return windowBreakpoint;
  };

  static getConfigurationForBreakpoint =
           <T extends string[] | Record<string, string>>(config: Partial<Record<WindowBreakpoint, T>>, breakpoint: WindowBreakpoint):
             T | undefined => {

             const breakpointKeys = Object.keys(WindowBreakpointUtils.breakpoints);

             let currentFoundConfiguration: any = undefined;
             let i;
             for (i = 0; i < breakpointKeys.length; i++) {
               const bp = breakpointKeys[i];
               currentFoundConfiguration = config[bp as WindowBreakpoint] || currentFoundConfiguration;
               if (bp === breakpoint) {
                 break;
               }
             }
             return currentFoundConfiguration;
           };
}

export default WindowBreakpointUtils;
