import {ReactElement} from "react";
import {Col, Container, Row} from "react-bootstrap";

const Legal = (): ReactElement => {

  return (
    <div className={'siteContainer'}>
      <Container>
        <Row>
          <Col md={12}>
            <div className={'headline'}>Datenschutz</div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className={'infoText'}>

            <div className="entry-content">

              <h4>§ 1 Information über die Erhebung personenbezogener Daten</h4>
              <ol>
                <li>Im Folgenden informieren wir über die Erhebung personenbezogener Daten bei der Nutzung unserer Website. Personenbezogene Daten sind alle Daten, die auf Sie persönlich beziehbar sind, z.B. Name, Adresse, E-Mail-Adresse, Nutzerverhalten.</li>
                <li>Verantwortlich gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung (DSGVO) ist:</li>
              </ol>
              <p>Nadlergalerie<br/>
                Sebastian Pöschl<br/>
                <br/>
                </p>
              <p><u><a href="mailto:info@nadlergalerie.de"><span>info@nadlergalerie.de</span></a></u> <i> </i></p>
              <p>(siehe unser Impressum <span><u><a href={"contact"}>Kontakt</a></u></span>).</p>
              <ol start={3}>
                <li>Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder über ein Kontaktformular werden die von Ihnen mitgeteilten Daten (Ihre E-Mail Adresse, ggf. Ihr Name und Ihre Telefonnummer) von uns gespeichert, um Ihre Fragen zu beantworten. Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungsfristen bestehen.</li>
                <li>Falls wir für einzelne Funktionen unseres Angebots auf beauftragte Dienstleister zurückgreifen oder ihre Daten für werbliche Zwecke nutzen möchten, werden wir Sie untenstehend im Detail über die jeweiligen Vorgänge informieren. Dabei nennen wir auch die festgelegten Kriterien der Speicherdauer.</li>
              </ol>
              <h4>§ 2 Ihre Rechte</h4>
              <ol>
                <li>Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:
                  <ol type="a">
                    <li>Recht auf Auskunft,</li>
                    <li>Recht auf Berichtigung und Löschung,</li>
                    <li>Recht auf Einschränkung der Verarbeitung,</li>
                    <li>Recht auf Widerspruch gegen die Verarbeitung,</li>
                    <li>Recht auf Datenübertragbarkeit.</li>
                  </ol>
                </li>
                <li>Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer personenbezogenen Daten durch uns zu beschweren.</li>
              </ol>
              <h4>§ 3 Erhebung personenbezogener Daten bei Besuch unserer Website</h4>
              <ol>
                <li>Bei der bloß informatorischen Nutzung der Website, also wenn Sie sich nicht registrieren oder uns anderweitige Informationen übermitteln, erheben wir nur die personenbezogenen Daten, die Ihr Browser an unseren Server übermittelt. Wenn Sie unsere Website betrachten möchten, erheben wir die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und Stabilität und Sicherheit zu gewährleisten (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO):
                  <ol type="a">
                    <li>IP-Adresse</li>
                    <li>Datum und Uhrzeit der Anfrage</li>
                    <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
                    <li>Inhalt der Anforderung (konkrete Seite)</li>
                    <li>Zugriffsstatus/HTTP-Statuscode</li>
                    <li>Jeweils übertragene Datenmenge</li>
                    <li>Website, von der die Anforderung kommt</li>
                    <li>Browser</li>
                    <li>Betriebssystem und dessen Oberfläche</li>
                    <li>Sprache und Version der Browsersoftware.</li>
                  </ol>
                </li>
              </ol>
              <p>&nbsp;</p>
              <ol start={2}>
                <li>Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung unserer Website Cookies auf Ihrem Rechner gespeichert. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen verwendeten Browser zugeordnet gespeichert werden und durch welche der Stelle, die den Cookie setzt (hier durch uns), bestimmte Informationen zufließen. Cookies können keine Programme ausführen oder Viren auf Ihren Computer übertragen. Sie dienen dazu, das Internetangebot insgesamt nutzerfreundlicher und effektiver zu machen.</li>
                <li>Einsatz von Cookies:
                  <ol type="a">
                    <li>Dies Website nutzt transiente Cookies, deren Umfang und Funktionsweise im Folgenden erläutert wird: Transiente Cookies werden automatisiert gelöscht, wenn Sie den Browser schließen. Dazu zählen insbesondere Session-Cookies. Diese speichern eine sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf unsere Website zurückkehren. Dies Session-Cookies werden gelöscht, wenn Sie sich ausloggen oder den Browser schließen.</li>
                    <li>Sie können Ihre Browser-Einstellungen entsprechend Ihren Wünschen konfigurieren und z.B. die Annahme von Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie eventuell nicht alle Funktionen dieser Website nutzen können.</li>
                  </ol>
                </li>
              </ol>
              <ol start={3}>
                <li>Wenn Sie mit uns in Kontakt treten (z. B. per E-Mail), verarbeiten wir Ihre Angaben zur Bearbeitung der Anfrage sowie für den Fall, dass Anschlussfragen entstehen. Erfolgt die Datenverarbeitung zur Durchführung vorvertraglicher Maßnahmen, die auf Ihre Anfrage hin erfolgen, bzw., wenn Sie bereits unser Kunde sind, zur Durchführung des Vertrages, ist Rechtsgrundlage für diese Datenverarbeitung Art. 6 Abs. 1 S. 1 b) DSGVO. Weitere personenbezogene Daten verarbeiten wir nur, wenn Sie dazu einwilligen (Art. 6 Abs. 1 S. 1 a) DSGVO) oder wir ein berechtigtes Interesse an der Verarbeitung Ihrer Daten haben (Art. 6 Abs. 1 S. 1 f) DSGVO). Ein berechtigtes Interesse liegt z. B. darin, auf Ihre E-Mail zu antworten</li>
              </ol>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default Legal;