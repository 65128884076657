import React, {FunctionComponent, ReactElement} from "react";
import {Route, Routes} from "react-router-dom";
import ArtistStephanieMai from "./Artists/2022/ArtistStephanieMai";
import ArtistLuiseWalter from "./Artists/2022/ArtistLuiseWalter";
import ArtistSteffenMertens from "./Artists/2022/SteffenMertens";
import {Archive} from "./Archive";
import {EvelinWaldmann} from "./Artists/2023/EvelinWaldmann";
import {JensPuppe} from "./Artists/2023/JensPuppe";
import {FranziskaGussmann} from "./Artists/2023/FranziskaGussmann";
import {UweSchaffranietz} from "./Artists/2023/UweSchaffranietz";

export const ArchiveList: FunctionComponent = (): ReactElement => {
  return (
      <>
        <Routes>
          <Route path="/stephanie/*" element={<ArtistStephanieMai/>}/>
          <Route path="/luise/*" element={<ArtistLuiseWalter/>}/>
          <Route path="/steffen/*" element={<ArtistSteffenMertens/>}/>
          {/**/}
          <Route path={"evelin"} element={<EvelinWaldmann/>}/>
          <Route path={"jens"} element={<JensPuppe/>}/>
          <Route path={"franziska"} element={<FranziskaGussmann/>}/>
          <Route path={"uwe"} element={<UweSchaffranietz/>}/>
          {/**/}
          <Route path={"*"} element={<Archive/>}/>
        </Routes>
      </>
  )
}